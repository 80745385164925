import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/david-george-otte.jpg";
  const fullName = `David G. Otte`;
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = new Date(1962, 2 - 1, 9);
  const deathDate = new Date(2021, 1 - 1, 19);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 28);
  const memorialTime = "7pm CST";
  const inviteText = `Please join us in remembering and celebrating the life of Dave Otte born Feb. 9th, 1962 and passed January 19, 2021. Due to unforeseen circumstances and current restrictions we will be holding a virtual service to honor his life.`;
  const inviteChild = <></>;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/ffe50drrc";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rk56q2oi38runuvk4y41op89zlpgpsnavvu4qlib";
  const recordingEmbedDirectUrl = [
    {
      poster: "/images/people/Dave-Cotte-Intro.png",
      url: "https://storage.googleapis.com/afterword-static/recordings/DaveOtte-recording.mp4",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        David G Otte, Age 58 of Cottage Grove, MN passed away peacefully with
        family at his side January 19, 2021. He was born in Northfield, MN and
        spent his younger years in Randolph on his grandparents’ farm. David was
        employed by Calspan ASE for over 40 years where he formed many
        long-lasting friendships. He had a love for his family, friends, job,
        golfing, and fishing. Dave had a huge impact on everyone he came into
        contact with and made the neighborhood in Cottage Grove a little
        brighter. He loved being with Colton’s friends when they would visit,
        and spent his later years supporting his great nephew Ryelan at his
        baseball, basketball and soccer games. Survived by his wife, Sheila; son
        Colton; stepmother Pat Otte; siblings Rick Otte, Kristine Otte, Shelly
        (Kevin) Bauer; nieces Jessica (Kevin) Willems, Candice Russell, nephew
        Justin (Ali) Otte; great nephew Ryelan Willems, great niece Ryan Otte,
        and many cousins, aunts, uncles and friends. And we can’t forget his
        prized Camaro that still has not seen a raindrop. Preceded in death by
        his parents, Patricia Johnson and Melvin Otte, special grandparents
        Milton and Dorothy Otte and George and Millie Czifro. A virtual service
        is set for January 28 at 7:00 pm with Afterword. A family Celebration of
        Life will be held at a later date.
      </p>
      <p>
        In lieu of flowers, memorial donations may be made to the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://makingagift.umn.edu/give/fund.html?id=23474"
        >
          U of M Caregiver Emergency Fund
        </a>
        . If you donate, we would love for you to check the box "Make this gift
        in honor or memory of someone" and make it in the name of Dave.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
